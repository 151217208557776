import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
import { TYPE, ExternalLink, StyledInternalLink } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { JSBI } from '@uniswap/sdk'
import { BIG_INT_ZERO } from '../../constants'
import { OutlineCard } from '../../components/Card'
import { ButtonPrimary } from 'components/Button'
import DoubleCurrencyLogo from 'components/DoubleLogo'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
  margin-top: 20px;
`

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

export default function Earn() {
  const { chainId } = useActiveWeb3React()

  // staking info for connected account
  const stakingInfos = useStakingInfo()

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  //const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>FLy liquidity mining</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens from Uniswap (UNI-V2) to receive FLy, the Franklin token.
                </TYPE.white>
              </RowBetween>{' '}
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://tokenfly.co/"
                target="_blank"
              >
                <TYPE.white fontSize={14}>Read more about FLy</TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        {stakingInfos?.map(stakingInfo => {
          return stakingInfo.active || JSBI.greaterThan(stakingInfo.stakedAmount.raw, BIG_INT_ZERO) ? (
            <OutlineCard key={stakingInfo.stakingRewardAddress}>
              <DataRow style={{ alignItems: 'baseline' }}>
                <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating pool </TYPE.mediumHeader>
                {JSBI.equal(stakingInfo.stakedAmount.raw, BIG_INT_ZERO) ? (
                  <DoubleCurrencyLogo
                    currency0={stakingInfo.tokens[0] ?? undefined}
                    currency1={stakingInfo.tokens[1] ?? undefined}
                    size={24}
                  />
                ) : (
                  <></>
                )}
                <Countdown exactEnd={stakingInfo.periodFinish} />
              </DataRow>
              <PoolSection>
                {JSBI.equal(stakingInfo.stakedAmount.raw, BIG_INT_ZERO) ? (
                  // <OutlineCard>No active pools!</OutlineCard>
                  <StyledInternalLink to={`/fly/${stakingInfo.stakingRewardAddress}`}>
                    <ButtonPrimary padding="8px" borderRadius="8px">
                      Deposit
                    </ButtonPrimary>
                  </StyledInternalLink>
                ) : (
                  <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
                )}
              </PoolSection>
            </OutlineCard>
          ) : (
            <></>
          )
        })}

        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            <OutlineCard>No active pools!</OutlineCard>
          ) : (
            <></>
          )}
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  )
}
